const MIA_DESIGN_REVISIONS = {
  'bpb0-00cj-ht9w': 'nmp0-00cg-4gv0',
  'dmh0-00cn-jw1r': 'djn0-00by-wu36',
  'dmh0-00cp-07tk': 'nmp0-00cg-4gv0',
  'dmh0-00cp-wfy3': 'njg0-00cn-5005',
  'dmh0-00cq-f8nu': 'djn0-00by-wu36',
  'gbj0-00cs-9g8e': 'dmh0-00cs-xnmx'
};

export function reviseMIADesign(cid) {
  return MIA_DESIGN_REVISIONS[cid] || cid;
}

export function extractDesignFromUrl(searchParams) {
  return searchParams.get('design') || searchParams.get('cid');
}

export function adjustDesignForIframedCatalog(
  options,
  iframer,
  isSiteWideSearch,
  urlDesign
) {
  // remove design when iframer, except when urlDesign (cid from querystring) has a value
  if (!options.design || ((iframer || isSiteWideSearch) && !urlDesign))
    delete options.design;
  return options;
}

export function isSingleColorDesign(colorLimit, designColorCount) {
  // colorLimit === 0 means there is no cap for the number of colors a product supports
  return colorLimit !== 0 && designColorCount > colorLimit;
}

// Make any last-minute changes to the design URL param
export function adjustDesign(options, hit, url, iframer, isSiteWideSearch) {
  const urlDesign = extractDesignFromUrl(url.searchParams);
  options.design = urlDesign || options.design;
  adjustDesignForIframedCatalog(options, iframer, isSiteWideSearch, urlDesign);
  options.design = reviseMIADesign(options.design);
  return options.design;
}

// Persist originalDesign in options but don't pass it as a URL param
export function extractMemoisedDesignDataFromOptions(options) {
  const {originalDesign: _, ...urlSearchParams} = options;
  return urlSearchParams;
}
