import {
  mmsImagesProduction,
  mmsImagesStaging
} from './constants/mmsImagesConstants';
import {
  adjustDesign,
  extractMemoisedDesignDataFromOptions
} from './design/designParam';
import {
  addMaterialBasedRenderingEffects,
  adjustRenderingEffects
} from './design/renderingEffects';

const filecache = {};

//TODO extract into .env files / env.js files
export const mmsImageBase = () =>
  isProd() ? mmsImagesProduction : mmsImagesStaging;

const DEFAULT_IMAGE_OPTIONS = {
  placeMax: 1,
  placeMaxPct: 0.8,
  placeUseProduct: 1,
  placeUseView: 'front'
};

export function getColorImage(
  colorId,
  hit,
  fingerprint = '',
  options = {},
  noLaydown,
  iframer,
  isLabImageEligible = true,
  isSiteWideSearch = false
) {
  options.size = options.size || 'medium';

  if (!isLabImageEligible && !noLaydown) return hit.style_card_image_url;

  options.design = adjustDesign(
    options,
    hit,
    new URL(window.location.href),
    iframer,
    isSiteWideSearch
  );

  const cachekey = `${colorId}-${JSON.stringify(options)}`;
  if (filecache[cachekey]) return filecache[cachekey];

  const url = new URL(
    `${mmsImageBase()}/${fingerprint}colors/${colorId}/views/alt/front_${options.size}${options.extended ? '_extended' : ''}.png`
  );

  adjustRenderingEffects(options);

  // Persist originalDesign in options but don't pass it as a URL param
  const params = new URLSearchParams(
    extractMemoisedDesignDataFromOptions(options)
  );

  params.sort();
  url.search = params.toString();
  addMaterialBasedRenderingEffects(hit, url);
  filecache[cachekey] = url.toString();
  return url.toString();
}

export const mmsImageFingerprint = (hit) => {
  let og = '';
  if (hit?.imageFingerprint) {
    return hit.imageFingerprint;
  }
  if (hit?.style_card_image_url) {
    og = hit.style_card_image_url;
  }
  if (hit.colors && hit.colors.length) {
    og = hit.colors[0].image_url || '';
  }
  const pos = og.indexOf('/images/catalog');
  if (pos > 2) {
    const segments = og.substring(pos + 10).split('/');
    if (segments[1].length < 10) return '';
    return `${segments[1]}/`;
  }
  return '';
};

export const getYdhParams = (fallbackUrl) => {
  const ydhParamsFilter = ([key, _val]) => !key.startsWith('ix', 0);
  return filterUrlSearchParams(fallbackUrl, ydhParamsFilter);
};

export function overrideUrlParamsForYDH(
  imgUrl,
  hit,
  design,
  ydhCustomSettings,
  ydhGeneralSettings
) {
  let url = new URL(imgUrl);
  let options = filterUrlSearchParams(url);

  if (ydhCustomSettings.skip_defaults_removal) {
    // Some color image urls don't contain placement related parameters so we extract them from the category_images ixParams.
    // Otherwise we'd have bad image placements.
    let ixParams = {};
    if (hit?.category_images?.length > 0) {
      // Not all hits have category_images unfortunately. Find the first category_image with ix_params set.
      ixParams = hit?.category_images?.find(
        (obj) => Object.keys(obj?.ix_params || {}).length !== 0
      )?.ix_params;
    }
    options = {...ixParams, ...options};
  } else {
    // Remove the default placement options unless the custom YDH settings tell otherwise. This is the default behavior.
    delete options.placeMax;
    delete options.placeMaxPct;
    delete options.placeUseProduct;
    delete options.placeUseView;
  }

  // ydh default params < color default params < custom params (param precedence)
  options = {
    ...ydhGeneralSettings.defaultSettings,
    ...options,
    ...ydhCustomSettings
  };

  // Remove autonegate option if set by the custom YDH settings
  if (ydhCustomSettings.skip_autonegate) {
    options.autoNegate = 0;
  }

  removeNonUrlQueryParams(options, ydhGeneralSettings.nonUrlQueryParams);

  options.design = design;

  const params = new URLSearchParams(options);
  params.sort();
  url.search = params.toString();

  return url.toString();
}

// private

const isProd = () => {
  const env =
    document.querySelector('meta[property="rails_env"]')?.content || 'test';
  return env === 'production';
};

// Delete YDH config attributes we don't want to pass to MMS images but are used for other purposes (skip autonegate, etc.)
const removeNonUrlQueryParams = (options, nonUrlQueryParams) => {
  nonUrlQueryParams.forEach((key) => {
    delete options[key];
  });
  return options;
};

// By default returns all url search params as the default filter returns true to any value
export const filterUrlSearchParams = (
  stringUrl,
  filter = ([_key, _val]) => true
) => {
  const url = new URL(stringUrl);
  const entries = Array.from(url.searchParams.entries());
  return entries
    .filter(filter)
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
};
