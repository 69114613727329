import Cookies from 'js-cookie';

export function getProductDataUrl(productLink) {
  const baseUrl = window.location.origin
  return baseUrl + productLink
}

export function getProductDataImageUrl(hitId) {
  return `https://mms-images.out.customink.com/mms/images/catalog/colors/${hitId}/views/alt/front_small_extended.png`
}

export function getEcomDataLayer(hit) {
  const items = {
    "item_id": parseInt(hit.product_id),
    "item_name": hit.name,
    "item_brand": hit.manufacturer,
    "item_category": hit.breadcrumbs?.[0]?.name?.toLowerCase()?.replace(/\s/g,"_"),
    "item_category1": hit.breadcrumbs?.[1]?.name?.toLowerCase()?.replace(/\s/g,"_"),
    "item_category2": hit.breadcrumbs?.[2]?.name?.toLowerCase()?.replace(/\s/g,"_"),
    "currency": "USD",
    "item_variant": null,
  }
  return [items]
}

export function getPageViewEvent(category, keyword) {
  const iframer = ProductCatalog.IFrame.isFramed() ? `${ProductCatalog.IFrame.iframer()}/` : '/'
  const emptyCategoryData = Object.values(category).every(x => x === null || x === '');
  const categoryData = (emptyCategoryData || keyword) ? 'custom' : `${category.id}/${category.name}`
  const keywordData = keyword ? `?keyword=${keyword}` : null;
  return `${iframer}catalog/styles/${categoryData}${keywordData ? keywordData : ''}`
}

export function fireToolTipAnalytics(categoryId, categoryName, styleName) {
  CustomInk.Metrics.sessionPageViewTag(`/catalog/styles/${categoryId}/${categoryName}`);
  CustomInk.Metrics.fireEvent(
    'product styles',
    'pricing details',
    styleName
  );
}

export function getOctoIdFromCookie() {
  const oeValue = Cookies.get('oe');
  return oeValue && oeValue.substring(0, 64);
}

export function getSessionFromCookie() {
  const value = Cookies.get('session_token');
  return value && value.substring(0, 64);
}
